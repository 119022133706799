html {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
}

body {
  padding-top: 3.5rem;
  font-family: "SF Pro SC","SF Pro Text",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif;
  color: #333;
  font-size: 0.875rem;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  background-color: #fff;
}

.p-20 {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.h5 {
  font-size: 1rem;
}

a {
  color: #333;
  text-decoration: none;
}

a:active,
a:focus,
a:hover {
  color: #1a1919;
  text-decoration: none;
}

.text-theme-color {
  color: #e11a0c;
}

.footer {
  background: #30343D;
}

.navbar {
  border-bottom: 1px solid #efefef;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.06), 0 1px 10px 0 rgba(0,0,0,.04), 0 0 4px -1px rgba(0,0,0,.08);
}

.navbar .nav-link {
  font-size: 1rem;
}

.navbar-brand span {
  font-size: 1.375rem;
  font-weight: 600;
}

.navbar-brand span:first-child {
  margin-right: 0.25rem;
}

.navbar-brand .en {
  font-size: 1.375rem;
  font-family: 'Helvetica Neue';
  text-transform: uppercase;
}

.navbar-brand .en:last-child {
  color: #e11a0c;
  opacity: 0.8;
}

.main h1 {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
}

.article {
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-top: 1rem; 
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-align: justify;
  word-break: break-word;
}

.article figure {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1.25rem;
}

.article a {
  color: #e11a0c;
  border-bottom: 1px solid transparent;
  transition: border 0.2s;
}

.article a:hover {
  border-bottom: 1px solid #e11a0c;
}

.article ul,
.article ol {
  list-style: none;
  padding-left: 0;
}

.article p {
  hyphens: auto;
  margin: 0 0 1.6rem;
}

.article p:empty {
  margin: 0;
}

.time,
.article-meta {
  color: #b3b2b2;
}

.article-tag {
  display: inline-block;
  background: #ededed;
  color: #4d4c4c;
  font-size: .875rem;
  line-height: 1.25rem;
}

.article-tag:hover {
  background: #e7e7e7;
  color: #333;
}

.article-tips,
.article-meta .author {
  color: #737373;
}

.article-tips {
  background: #f5f5f5;
  border-color: #e6e5e5;
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
}

.article img,
.article video {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1.25rem;
}

.article h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.article h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.article h4,
.article h5,
.article h6 {
  font-size: 1rem;
}

.share-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.resp-sharing-button__link,.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em 1em 0.5em 0;
}

.resp-sharing-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 25ms ease-out;
  height: 45px;
  width: 45px;
  font-size: 1rem;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button img {
  display: block;
  height: 100%;
  width: 100%;
}

.resp-sharing-button__icon svg {
  width: 1.5rem;
  height: 1.5rem
}

.resp-sharing-button--small svg {
  margin: 0;
  vertical-align: middle
}

.resp-sharing-button__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  stroke: #fff;
  fill: none
}

.resp-sharing-button__icon--solid,.resp-sharing-button__icon--solidcircle {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998
}

.resp-sharing-button--facebook:hover,.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee
}

.resp-sharing-button--twitter:hover,.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9
}

.resp-sharing-button--line {
  background-color: #00b901;
  border-color: #00b901;
}

.resp-sharing-button--line:hover,.resp-sharing-button--line:active {
  background-color: #00b901;
  border-color: #00b901;
}

.video-js {
  margin: auto;
  width: 100%;
}

.related-row {
  display: flex;
  flex-flow: row wrap;
  margin-left: -1rem;
  margin-right: -1rem;
}

.related-item {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: none;
  width: 100%;
  max-width: 100%;
  padding: 0.75rem 1rem;
}

.related-title {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-underline {
  border-bottom: 2px solid transparent;
}

.text-underline:hover {
  border-color: #333;
}

@media all and (min-width: 576px) {
  .related-item {
    width: 50%;
  }

  .p-20 {
    padding: 2rem;
  }
}

@media all and (max-width: 991px) {
  [class^=col-] {
    padding-left: 0;
    padding-right: 0;
  }
}

.underline {
  position: relative;
  z-index: 1;
}

.underline::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.375rem;
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: #e11a0c;
  z-index: -1;
}

.search-input {
  border-radius: 10rem;
  background: #f1f1f1;
  height: 2rem;
  line-height: 2rem;
  outline: none;
  border: none;
  padding: 0 16px;
  transition: all .3s ease;
}

.search-input:focus {
  background: #e6e6e6;
  box-shadow: none;
}

.search-input::placeholder {
  color: rgba(0,0,0,.32);
}

.img-wrap {
  position: relative;
  padding-bottom: 133.33%;
  overflow: hidden;
}

.img-element {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1)
}

.article img.img-element {
  height: 100%;
}

.article .img-wrap {
  margin: 1.25rem auto;
}

.post-img-element {
  transform: scale(1.1)
}

.card-title.h5 {
  font-size: 1.25rem;
}

ul.blocks-gallery-grid {
  padding-left: 0;
}

li.blocks-gallery-item {
  list-style: none;
}

.page-link {
  color: #e11a0c;
}

.page-link:hover {
  color: #e11a0c;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgb(225 26 12 / 25%);
}

.live-item-cover {
  position: relative;
}

.live-item-cover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(transparent, transparent, rgba(0,0,0,.2), rgba(0,0,0,.9));
  pointer-events: none;
}

.live-item-badge {
  content: 'LIVE';
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  line-height: 1.5;
  padding: 0 0.375rem;
  text-align: center;
  color: white;
  background: #c00;
  font-size: 0.75rem;
  z-index: 2;
}

.live-item-user {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  z-index: 2;
}

.live-item-user > span {
  max-width: 7em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fluid_video_wrapper video,
.in-article-unit img,
.in-article-unit video {
  margin: 0;
}

.image-container {
  width: 100%;
}

.image-container > span {
  position: unset !important;
}

.image-container .image {
  object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: unset !important;
  filter: blur(0) !important;
}

.datinggold-unit {
  width: 360px;
  padding-bottom: 150%;
  max-width: 100%;
}

.datinggold-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.datinggold-attention {
  top: 30%;
  left: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  border-radius: 5px;
  z-index: 11;
}

.cta-btn {
  animation: heartbeat 2s ease-in-out infinite;
}

@keyframes heartbeat {
  0%, 100% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1.05);
  }
}

.live-icon-dot {
  margin-right: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background-color: white;
  animation: flashing 1.8s linear infinite;
}

@keyframes flashing {
  from, to {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }
}

.sidebar-top-banner {
  height: 250px;
}
