/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, figure, footer, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}


ul {
  margin-top: 0;
  margin-bottom: 1rem;
}


ul ul {
  margin-bottom: 0;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role="button"] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

summary {
  display: list-item;
  cursor: pointer;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.figure {
  display: inline-block;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

 .col-12, .col, .col-lg-4, .col-lg-8 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 768px) {
  .row-cols-md-3 > * {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}

@media (min-width: 992px) {
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::-webkit-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}

.form-control:disabled {
  background-color: #e9ecef;
  opacity: 1;
}

input[type="date"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

select.form-control[size] {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media (min-width: 576px) {
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled {
  pointer-events: none;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled):active:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #007bff;
  text-decoration: none;
}

.btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.btn-link:focus {
  text-decoration: underline;
}

.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.collapse:not(.show) {
  display: none;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat;
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.show {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-text:last-child {
  margin-bottom: 0;
}


.card-img-top {
  flex-shrink: 0;
  width: 100%;
}


.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6;
}

.page-link:hover {
  z-index: 2;
  color: #0056b3;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}

.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: .75s linear infinite spinner-border;
  animation: .75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border {
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
  }
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.bg-white {
  background-color: #fff !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.align-items-center {
  align-items: center !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.w-50 {
  width: 50% !important;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.mw-100 {
  max-width: 100% !important;
}


.my-0 {
  margin-top: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}


.my-2 {
  margin-top: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}


.my-3 {
  margin-top: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}


.px-1 {
  padding-right: 0.25rem !important;
}


.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}


.py-2 {
  padding-bottom: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}


.px-3 {
  padding-left: 1rem !important;
}


.py-4 {
  padding-top: 1.5rem !important;
}


.py-4 {
  padding-bottom: 1.5rem !important;
}


.py-5 {
  padding-top: 3rem !important;
}


.py-5 {
  padding-bottom: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}


.mx-auto {
  margin-right: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  
  .my-sm-0 {
    margin-top: 0 !important;
  }
  
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .mr-sm-2 {
    margin-right: 0.5rem !important;
  }
}

.text-center {
  text-align: center !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-white {
  color: #fff !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.visible {
  visibility: visible !important;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
}
/*# sourceMappingURL=bootstrap.css.map */
html {
  font-size: 16px;
  -webkit-tap-highlight-color: transparent;
}

body {
  padding-top: 3.5rem;
  font-family: "SF Pro SC","SF Pro Text",-apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",sans-serif;
  color: #333;
  font-size: 0.875rem;
  line-height: 1.5;
  text-rendering: optimizeLegibility;
  background-color: #fff;
}

.p-20 {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.h5 {
  font-size: 1rem;
}

a {
  color: #333;
  text-decoration: none;
}

a:active,
a:focus,
a:hover {
  color: #1a1919;
  text-decoration: none;
}

.text-theme-color {
  color: #e11a0c;
}

.footer {
  background: #30343D;
}

.navbar {
  border-bottom: 1px solid #efefef;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.06), 0 1px 10px 0 rgba(0,0,0,.04), 0 0 4px -1px rgba(0,0,0,.08);
}

.navbar .nav-link {
  font-size: 1rem;
}

.navbar-brand span {
  font-size: 1.375rem;
  font-weight: 600;
}

.navbar-brand span:first-child {
  margin-right: 0.25rem;
}

.navbar-brand .en {
  font-size: 1.375rem;
  font-family: 'Helvetica Neue';
  text-transform: uppercase;
}

.navbar-brand .en:last-child {
  color: #e11a0c;
  opacity: 0.8;
}

.main h1 {
  font-weight: bold;
  font-size: 1.875rem;
  line-height: 2.5rem;
}

.article {
  margin: 0 auto;
  padding-bottom: 1rem;
  padding-top: 1rem; 
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.75rem;
  text-align: justify;
  word-break: break-word;
}

.article figure {
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 1.25rem;
}

.article a {
  color: #e11a0c;
  border-bottom: 1px solid transparent;
  transition: border 0.2s;
}

.article a:hover {
  border-bottom: 1px solid #e11a0c;
}

.article ul {
  list-style: none;
  padding-left: 0;
}

.article p {
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  margin: 0 0 1.6rem;
}

.article p:empty {
  margin: 0;
}

.time,
.article-meta {
  color: #b3b2b2;
}

.article-tag {
  display: inline-block;
  background: #ededed;
  color: #4d4c4c;
  font-size: .875rem;
  line-height: 1.25rem;
}

.article-tag:hover {
  background: #e7e7e7;
  color: #333;
}


.article-meta .author {
  color: #737373;
}

.article img,
.article video {
  display: block;
  width: 100%;
  height: auto;
  margin-bottom: 1.25rem;
}

.article h2 {
  margin-bottom: 1rem;
  font-size: 1.5rem;
}

.article h3 {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.article h4,
.article h5,
.article h6 {
  font-size: 1rem;
}

.share-buttons {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.resp-sharing-button__link,.resp-sharing-button__icon {
  display: inline-block
}

.resp-sharing-button__link {
  text-decoration: none;
  color: #fff;
  margin: 0.5em 1em 0.5em 0;
}

.resp-sharing-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: 25ms ease-out;
  height: 45px;
  width: 45px;
  font-size: 1rem;
  font-family: Helvetica Neue,Helvetica,Arial,sans-serif
}

.resp-sharing-button img {
  display: block;
  height: 100%;
  width: 100%;
}

.resp-sharing-button__icon svg {
  width: 1.5rem;
  height: 1.5rem
}

.resp-sharing-button__icon {
  display: flex;
  stroke: #fff;
  fill: none
}

.resp-sharing-button__icon--solid {
  fill: #fff;
  stroke: none
}

.resp-sharing-button--facebook {
  background-color: #3b5998;
  border-color: #3b5998
}

.resp-sharing-button--facebook:hover,.resp-sharing-button--facebook:active {
  background-color: #2d4373;
  border-color: #2d4373
}

.resp-sharing-button--twitter {
  background-color: #55acee;
  border-color: #55acee
}

.resp-sharing-button--twitter:hover,.resp-sharing-button--twitter:active {
  background-color: #2795e9;
  border-color: #2795e9
}

.related-title {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-underline {
  border-bottom: 2px solid transparent;
}

.text-underline:hover {
  border-color: #333;
}

@media all and (min-width: 576px) {

  .p-20 {
    padding: 2rem;
  }
}

@media all and (max-width: 991px) {
  [class^=col-] {
    padding-left: 0;
    padding-right: 0;
  }
}

.underline {
  position: relative;
  z-index: 1;
}

.underline::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -0.375rem;
  height: 0.25rem;
  border-radius: 0.25rem;
  background-color: #e11a0c;
  z-index: -1;
}

.search-input {
  border-radius: 10rem;
  background: #f1f1f1;
  height: 2rem;
  line-height: 2rem;
  outline: none;
  border: none;
  padding: 0 16px;
  transition: all .3s ease;
}

.search-input:focus {
  background: #e6e6e6;
  box-shadow: none;
}

.search-input::-webkit-input-placeholder {
  color: rgba(0,0,0,.32);
}

.search-input:-ms-input-placeholder {
  color: rgba(0,0,0,.32);
}

.search-input::placeholder {
  color: rgba(0,0,0,.32);
}

.img-wrap {
  position: relative;
  padding-bottom: 133.33%;
  overflow: hidden;
}

.img-element {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -webkit-transform: scale(1.1);
          transform: scale(1.1)
}

.article img.img-element {
  height: 100%;
}

.article .img-wrap {
  margin: 1.25rem auto;
}

.card-title.h5 {
  font-size: 1.25rem;
}

.page-link {
  color: #e11a0c;
}

.page-link:hover {
  color: #e11a0c;
}

.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgb(225 26 12 / 25%);
}

.live-item-cover {
  position: relative;
}

.live-item-cover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(transparent, transparent, rgba(0,0,0,.2), rgba(0,0,0,.9));
  pointer-events: none;
}

.live-item-badge {
  content: 'LIVE';
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  line-height: 1.5;
  padding: 0 0.375rem;
  text-align: center;
  color: white;
  background: #c00;
  font-size: 0.75rem;
  z-index: 2;
}

.live-item-user {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  color: white;
  z-index: 2;
}

.live-item-user > span {
  max-width: 7em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.in-article-unit img,
.in-article-unit video {
  margin: 0;
}

.image-container {
  width: 100%;
}

.image-container > span {
  position: static !important;
  position: initial !important;
}

.image-container .image {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100% !important;
  position: relative !important;
  height: auto !important;
  height: initial !important;
  -webkit-filter: blur(0) !important;
          filter: blur(0) !important;
}

.datinggold-unit {
  width: 360px;
  padding-bottom: 150%;
  max-width: 100%;
}

.datinggold-bg {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.datinggold-attention {
  top: 30%;
  left: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  box-shadow: 0 5px 15px rgb(0 0 0 / 50%);
  border-radius: 5px;
  z-index: 11;
}

.cta-btn {
  -webkit-animation: heartbeat 2s ease-in-out infinite;
          animation: heartbeat 2s ease-in-out infinite;
}

@-webkit-keyframes heartbeat {
  0%, 100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}

@keyframes heartbeat {
  0%, 100% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  50% {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }
}

.live-icon-dot {
  margin-right: 0.25rem;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 1rem;
  background-color: white;
  -webkit-animation: flashing 1.8s linear infinite;
          animation: flashing 1.8s linear infinite;
}

@-webkit-keyframes flashing {
  from, to {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }
}

@keyframes flashing {
  from, to {
    opacity: 1;
  }

  50% {
    opacity: 0.25;
  }
}

.sidebar-top-banner {
  height: 250px;
}

